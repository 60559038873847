.close-button {
  border: none;
  position: absolute;
  right: 16px;
  top: 16px;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.15);
  backdrop-filter: blur(7px);
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
}

.close-button:active {
  transform: scale(1.2);
}

.modal_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  justify-content: space-around;
}

.modal_img {
  /* padding-bottom: 26px; */
  width: 110px;
}

.modal_title {
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  margin-bottom: 16px;
}

.modal_under-title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: 22.909px;
  /* padding-bottom: 38px */
}

.modal_img-coin-boosts {
  height: 27px;
  margin-right: 10px;
}

.modal_balance_coins-boosts {
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.modal_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__total-div-boosts {
  font-size: xxx-large;
  color: white;
  bottom: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  
}

.modal__button-big-yellow {
  color: #232529;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  width: 80%;
  max-width: 366px;
  height: 48px;
  padding: 12px 24px;
  border-radius: 16px;
  background: linear-gradient(180deg, #ffc700 0%, #d78100 100%);
  transition: transform 0.3s ease;
  margin-bottom: 16px;
}

.modal__button-big-yellow:disabled {
  background: #999999;
  background: linear-gradient(to bottom, #999999, #cccccc); /* Dark gray to light gray */
  box-shadow: inset 0 1px 0 0 #ffffff;
  color: #999999;
  pointer-events: none;
  cursor: not-allowed;
}

.modal__button-big-yellow:active {
  transform: scale(1.03);
}



@media screen and (max-height: 700px) {
  .modal_body {
    padding-top: 10px;
  }

  .modal_img {
    width: 20%;
  }

  .modal_title {
    font-size: 26px;
  }

  .modal_under-title {
    font-size:  12px;
  }
}
