@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
}

body {
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
}

mB-10 {
    margin-bottom: 10px;
}

.mR-4 {
    margin-right: 4px;
}

.mR-12 {
    margin-right: 12px;
}

.wrapper-king {
    margin: 0 auto;
    width: 100%;
    max-width: 430px;
    /* min-width: 350px; */
    display: flex;
    height: 100vh;
    position: relative;
    padding: 0 15px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    background: url(/public/assets/img/league/stars.png),
        linear-gradient(180deg,
            #05011e 7.69%,
            #2602b7 23%,
            #3303f3 25.5%,
            #047abc 37.5%,
            #570573 52.23%,
            #070120 76.93%,
            #000423 95.38%);
    background-position: top;
}

.top-imgs-king {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 23px;
}

.confetti-king {
    width: 264px;
}


.semi-significant-text-king {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    line-height: 22.909px;
    /* 143.182% */
    max-width: 250px;
    margin-bottom: 16px;

}

.significant-bg {
    width: 100%;
    padding: 50px 8px 16px 8px;
    border-radius: 24px;
    background: rgba(217, 217, 217, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    margin-top: -55px;
}

.button-big-yellow-king {
    color: #232529;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    height: 48px;
    padding: 12px 24px;
    border-radius: 16px;
    background: linear-gradient(180deg, #ffc700 0%, #d78100 100%);
    transition: transform 0.3s ease;
}

.little-wthite-text {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 274;
    line-height: 18.9px;
}

.row-coin-king {
    margin-right: 5px;
    margin-top: -2px;
}

.small-gold-text {
    color: #F4CD27;
    font-size: 12px;
    font-style: normal;
    font-weight: 274;
    line-height: 18.9px;
}