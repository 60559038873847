@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  overflow: hidden;
  -webkit-overflow-scrolling: touch !important;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/* #root {
  width: 100%;
  display: flex;
  align-items: center;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: url("/public/assets/img/stars.png"),
    linear-gradient(
      180deg,
      #05011e 0%,
      #140148 52.23%,
      #070120 76.93%,
      #000423 95.38%
    );
  background-repeat: no-repeat;
  background-position: top;
  font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
  color: white;
  transition: background-color 0.3s ease, background-image 0.3s ease;
}

.wrapper {
  margin: 0 auto;
  max-width: 430px;
  min-width: 350px;
  display: flex;
  height: 100vh;
  position: relative;
  padding: 0 23px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.stopVertScroll {
    overflow-y: hidden; /* Запретить вертикальный скролл */
}

.plus-icon {
  margin-top: -4px;
  margin-right: 3px;
}

.button-container {
  display: flex;
  margin-bottom: 56px;
}

.button {
  height: 48px;
  margin: 0 auto;
  margin-top: 31px;
  color: white;
  top: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
}

.button:active {
  transform: scale(1.1);
}

.button.hand {
  margin-right: 12px !important;
}

.button.diamond-container {
  align-items: center;
}

.button-icon,
.hand-ok {
  width: 26px;
  margin-right: 10px;
}

.hand-ok {
  width: 26px;
  margin-right: 10px;
}

.diamond {
  width: 22px;
  margin-right: 10px;
}

.plus-white {
  width: 24px;
}

.button-text {
  color: #fff;
  text-align: center;
  font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;
}

.img-coin {
  height: 38px;
  margin-right: 10px;
}

.user {
  position: absolute;
  top: 5%;
  font-size: 12px;
  color: white;
}

.link {
  position: absolute;
  top: 20%;
  font-size: 12px;
  color: white;
}

.clicker-container {
  position: relative;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;
}

#clicker {
  max-width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: top 0.1s ease;
}

.clicker-img {
  width: 100%;
}

.logo {
  position: absolute;
  width: 30%;
  opacity: 0.9;
}

.light-logo {
  box-shadow: 0px 0px 80px 60px rgba(236, 136, 5, 0.7);
  position: absolute;
  top: 116px;
  left: 130px;
  transition: box-shadow 1s ease;
  opacity: 1;
}

.logo-top-container {
  position: absolute;
  top: 65px;
  left: 90px;
  z-index: 10;
  transition: top 0.1s ease;


}

.logo-top-container.active {
  transform: translateY(8px);
}

.logo-top {
  width: 80px;
}

.logo-center {
  top: 101px;
  width: 10%;
  left: 116px;
  transition: top 0.05s ease;

}

.logo-bottom.active {
  transform: translateY(-8px);
}

.logo-bottom {
  top: 100px;
  left: 90px;
  width: 80px;
  transition: top 0.1s ease;
}

/* Lighting */
.back-circle {
  position: absolute;
  transition: box-shadow 0.5s ease;
}

.footer {
  width: 100%;
  height: 215px;
  display: flex;
  flex-direction: column;
}

.energy-section {
  width: 100%;
  border-radius: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 24px;
}

.energy-div {
  width: 100%;
}

.energy-score-container {
  display: flex;
  text-align: right;
}

.energy-container {
  padding-top: 7px;
  min-width: 75px;
  max-width: 50%;
}

.fire-icon {
  width: 28px;
  height: 46px;
}

.energy {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 510;
  line-height: 17.03px;
  margin-bottom: 5px;
}

.energy-storage {
  opacity: 0.9;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-style: normal;
  font-weight: 510;
}

.progress-bar-section {
  position: relative;
  padding-top: 12px;
}

.progress-empty {
  border-radius: 4px;
  background: #000;
  box-shadow: 0px 0px 9px 0px rgba(255, 181, 13, 0.8);
  height: 8px;
  z-index: 1;
}

.progress-bar-full {
  width: 100%;
  height: 15px;
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2;
  position: absolute;
  top: 55px;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #b15219, #ffc700);
  position: relative;
}

.mini-buttons-container {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  /*border-radius: 24px;*/
  /*background: rgba(217, 217, 217, 0.05);*/
  margin-bottom: 24px;
}

.mini-button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  /*backdrop-filter: blur(7px);*/
  transition: transform 0.3s ease;
}

.mini-button:active {
  transform: scale(1.1);
}

.mini-button span {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;
  /* 145.385% */
}

.mini-button-icons {
  width: 32px;
  height: 32px;
}

.touch-feedback2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  opacity: 1;
  animation: fly-up 1.5s ease-out;
  color: white;
  font-weight: bold;
  font-size: 24px;
}

@keyframes fly-up {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-300px);
  }
}

.hide {
  opacity: 0;
  transform: translateY(-50px);
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(1) translateY(0);
  }

  50% {
    transform: scale(1.01) translateY(-3px);
  }
}

.bounce-on-click {
  animation: bounce 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@media screen and (max-height: 610px) {
  .button-container {
    display: flex;
    margin-bottom: 3%;
  }

  .clicker-container {
    padding-bottom: 0px;
  }
}

.text-center {
  text-align: center;
}