* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
}

.white {
    color: white;
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
}


body .league {
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: url(/public/assets/img/league/stars.png),
        linear-gradient(180deg, #05011E 16.69%, #C45E00 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);

    background-position: top;
    font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
}

.test {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
    color: #ffffffe8;
}

.wrapper-league {
    background: url(/public/assets/img/league/stars.png);
    background-position: top;
    margin: 0 auto;
    width: 100%;
    max-width: 430px;
    display: flex;
    height: 100vh;
    position: relative;
    padding: 0 15px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;


    /*  кастомные стили для полосы прокрутки */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer и Edge */
}


/*  кастомные стили для полосы прокрутки */
wrapper-league ::-webkit-scrollbar {
    display: none;
    /* Safari и Chrome */
}

.header-league {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.button-league {
    width: 100%;
    height: 48px;
    margin: 0 auto;
    margin-top: 31px;
    margin-bottom: 48px;
    color: white;
    top: 22%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 12px 16px;

    border-radius: 16px;
    background: rgba(217, 217, 217, 0.05);
    backdrop-filter: blur(7px);
    transition: transform 0.3s ease;
}

.button-league:active {
    transform: scale(1.03);
    /* Уменьшаем размер кнопки на 5% при активации */
}

.diamonds {
    width: 93px;
    position: absolute;
    top: 115px;
    left: 245px;
    z-index: 2;
}

.diamonds-league {
    width: 93px;
    position: absolute;
    top: 115px;
    left: 245px;
    z-index: 2;
}

.diamond-blink1-league {
    position: absolute;
    top: 208px;
    left: 90px;
}

.diamond-blink2-league {
    position: absolute;
    top: 194px;
    left: 243px;
}

.diamond-blink3-league {
    position: absolute;
    top: 219px;
    left: 165px;
}

.total-container-league {
    display: flex;
    align-items: center;
}

.total-score-league {
    margin-right: 10px;
}

.img-coin-league {
    height: 27px;
    margin-right: 10px;
}

.view-container-league {
    width: calc(100% - 32px);
    border-radius: 24px;
    background: rgba(217, 217, 217, 0.05);
    position: relative;
    padding: 27px 34px 32px 34px;

    /* Убрать после создания слайдера */
    margin-bottom: 10px;

}

.text-container-league {
    margin-bottom: 34px;
    padding-left: 23px;
}

.text-league-league {
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    /* 96.875% */
}

.text-from-league {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    line-height: 22.909px;
    /* 143.182% */
    padding-top: 12px
}

.progress-bar-container-league {
    position: relative;
    width: 100%;
    height: 15px;
    /* border: 1px solid red; */
}

.progress-bar-section-league {
    width: 99%;
    position: relative;
    padding-top: 4px;
}

.progress-empty-league {
    border-radius: 4px;
    background: #000;
    box-shadow: 0px 0px 9px 0px rgba(255, 181, 13, 0.80);
    height: 8px;
    z-index: 1;
}

.progress-bar-full-league {
    width: 100%;
    height: 15px;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;
    position: absolute;
    top: 15px;
}

.progress-bar-league {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #B15219, #FFC700);
    position: relative;
}

/* Заглушка для дизайна */
.page-indicator-league {
    margin-bottom: 2px;
}

/* Кнопки слайдера влево/вправо */
.slider-button-league {
    position: absolute;
    padding: 12px;
    border-radius: 16px;
    background: rgba(217, 217, 217, 0.15);
    backdrop-filter: blur(7px);
    top: 32%;
    transition: transform 0.3s ease;
}

.slider-button-league.left {
    left: -16px;
}

.slider-button-league.right {
    left: calc(100% - 32px);
}

.slider-button-league:active {
    transform: scale(1.1);
}

/* Точки навигации под слайдером */
.navigation-points {
    display: flex;
    gap: 4px;
}

.navigation-points .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.32);
    margin-bottom: 10px;
}

.navigation-points .circle.active {
    background-color: rgba(255, 255, 255, 1);
}


/* --------------------------------------------------main-table-------------------------------------------------- */
.main-table-league {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 24px 24px 24px 24px;
    background: rgba(217, 217, 217, 0.05);
    margin-bottom: 30px;
}

.player-info-league {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: white;
    display: flex;
    align-items: center;
    padding: 8px 16px;

    border-radius: 16px;
    background: rgba(217, 217, 217, 0.05);
    backdrop-filter: blur(7px);
    transition: transform 0.3s ease;
}

.main-info-list-league {
    width: 70%;
    display: flex;
    align-items: center;
}

.playaer-list-li-league {
    position: relative;
}

.player-info-league:first-child::after {
    content: url('/public/assets/img/league/1-place.png');
    position: absolute;
    top: 0;
    left: 55%;
    z-index: 1;
}

.player-info-league:nth-child(2)::after {
    content: url('/public/assets/img/league/2-place.png');
    position: absolute;
    top: 0;
    left: 55%;
    z-index: 1;
}

.player-info-league:nth-child(3)::after {
    content: url('/public/assets/img/league/3-place.png');
    position: absolute;
    top: 0;
    left: 55%;
    z-index: 1;
}

.list-number-league {
    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 590;
    line-height: 18.9px;
    /* 145.385% */
    margin-right: 12px;
}

.avatar-league {
    margin-right: 12px;
}

.player-info-text-league {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 67%;
    height: 48px;
    padding: 3px 0px 1px 0px;
    /* border: 2px solid yellow; */
}

.player-name-league {
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 590;
    line-height: 18.9px;
    /* 145.385% */
}

.row-coin-league {
    margin-right: 5px;
}

.row-player-score-league {
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 274;
    line-height: 18.9px;
    /* 157.5% */
}

.player-list-league {
    width: 100%;
}

.item__user-shor-league {
    z-index: 1;
    position: absolute;
    font-size: 16px;
}

.item__user-avatar-league {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.item__user-template-league {
    position: relative;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #000;
}

#balanceCoinsSpan-league {
    color: #fff;
}

/* empty main table */

.main-table-league.empty-league {
    padding-top: 32px;
    padding-bottom: 100%;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.league-background-1 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #C45E00 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-2 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #5A6DD0 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-3 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #F29C1B 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-4 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #AB7102 26.49%, #D20097 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-5 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #00D254 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-6 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #00D2A0 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-7 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #1100D2 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-8 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #D20097 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-9 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #D20000 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}

.league-background-10 {
    background-image: url(/public/assets/img/league/stars.png), linear-gradient(180deg, #05011E 16.69%, #FD8DDD 35%, #570573 52.23%, #070120 76.93%, #000423 95.38%);
}