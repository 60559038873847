@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.left-align {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.width-100 {
  width: 100%;
}

.justify-end {
  justify-content: end;
}

.absolute {
  position: absolute;
}

.pgT-60 {
  padding-top: 60px !important;
}

.pgR-12 {
  padding-right: 12px;
}

.mrR-6 {
  margin-right: 6px;
}

.mrR-16 {
  margin-right: 16px;
}

.mrB-0 {
  margin-bottom: 0px !important;
}

.mrB-12 {
  margin-bottom: 12px;
}

.mrB-16 {
  margin-bottom: 12px !important;
}

.mrB-24 {
  margin-bottom: 24px;
}

.mrB-40 {
  margin-bottom: 40px;
}

.mrB-140 {
  margin-bottom: 140px !important;
}

.row-boosts {
  display: flex;
  width: 100%;
}

.gap-16 {
  gap: 16px;
}

.left-align {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.middle-title {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 22px;
  text-align: left;
}

.row {
  display: flex;
  width: 100%;
}

body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: url(/public/assets/img/league/stars.png),
    /* linear-gradient(180deg, #05011E 2.94%, #7C01C8 28.76%, #500573 68.63%, #000423 95.19%); */
      linear-gradient(
        180deg,
        #05011e 5.25%,
        #36025f 28.76%,
        #3c0573 38.36%,
        #070120 68.63%,
        #000423 95.19%
      );
  background-position: top;
  font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
}

.wrapper {
  width: 100%;
  max-width: 430px;
  /* min-width: 350px; */
  display: flex;
  height: 100vh;
  position: relative;
  padding: 0 15px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

/* ------------------------------------- */

.your-balance {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: 22.909px;
}

.total-div-boosts {
  font-size: xxx-large;
  color: white;
  bottom: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.balance_coins-boosts {
    line-height: 38px;
}

.img-coin-boosts {
  height: 38px;
  margin-right: 10px;
}

/* ------------------------------------- */

.small-button-white-text {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;

  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  padding: 12px 16px;
}

.small-button-white-text:active {
  transform: scale(1.1);
}

.small-button-white-text span {
  margin-right: 5px;
}

/* ------------------------------------- */

/* ------------------------------------- */

.boosters-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 24px;
  background: rgba(217, 217, 217, 0.05);
  padding: 15px;
  margin-bottom: 32px;
}

.invite-button {
  width: 100%;
  height: 69px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 24px 21px 12px;

  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
}

.invite-button-click:active {
  transform: scale(1.03);
}

.simple {
  flex-direction: column;
  justify-content: flex-start;
}

.claim-button {
  width: 100%;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 24px 21px 12px;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
}

.total-container {
  display: flex;
  align-items: center;
}

.img-coin {
  height: 27px;
  margin-right: 5px;
}

.mini-button-icons-boosters {
  width: 50px;
}

.multi-button-icons {
  width: 52px;
}

.one-time-bonus-multi {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: 18.9px;
  margin-bottom: 3px;
}

.small-text-button {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 274;
  line-height: 18.9px;
}

.two-row-boosts {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mrB-5 {
  margin-bottom: 5px;
}

.row-coin {
  padding-bottom: 2px;
}

.column-button {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 67%;
  height: 48px;
  padding: 7px 0px 0px 0px;
  /* border: 2px solid yellow; */
}

/* ------------------------------------- */

.square-button {
  width: 100%;
  margin: 0 auto;
  color: white;
  display: flex;
  align-items: flex-start;
  padding: 27px 0px 22px 15px;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
  flex-direction: column;
}

.square-butttom-icons {
  width: 80px;
  margin-bottom: 14px;
}

.title-white-middle {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: 18.9px;
  margin-bottom: 5px;
}

.bonuses-left {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 590;
  line-height: 18.9px;
}

.slash {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 274;
  line-height: 18.9px;
}

.bonuses-total {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 274;
  line-height: 18.9px;
}

.small-text-button-gold {
  color: #f4cd27;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 274;
}


/* react fix*/

.row-boosts-coin {
  margin: 0 5px;
}

@media screen and (min-width: 431px) {
  /* CSS стили, применяемые при ширине экрана больше 430px */
  .wrapper {
    overflow-x: visible;
  }
}
